import React, { useEffect } from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useDispatch } from 'react-redux';

import { checkUserRole, initiate } from 'store/actions';

import './App.scss';
import './scss/style.scss';

const Loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));
const SelectModal = React.lazy(() => import('./views/Modal'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const ForgotPassword = React.lazy(() =>
  import('./views/pages/forgotPassword/ForgotPassword')
);
const ResetPassword = React.lazy(() =>
  import('./views/pages/forgotPassword/ForgotPassword')
);
// const Register = React.lazy(() => import('./views/pages/register/Register'));
const VerifyAccount = React.lazy(() => import('./views/pages/register/Verify'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initiate());
    dispatch(checkUserRole());
  }, [dispatch]);

  return (
    <>
      <Router>
        <React.Suspense fallback={Loading}>
          <SelectModal />
        </React.Suspense>
        <React.Suspense fallback={Loading}>
          <Switch>
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/password/forgot"
              name="Forgot Password"
              render={(props) => <ForgotPassword {...props} />}
            />

            <Route
              exact
              path="/password/reset"
              name="Reset Password"
              render={(props) => <ResetPassword {...props} />}
            />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/account/verify"
              name="Register Page"
              render={(props) => <VerifyAccount {...props} />}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
            <Route
              path="/"
              name="Home"
              render={(props) => <TheLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </Router>
      <ToastContainer />
    </>
  );
};

export default App;
